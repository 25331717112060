import { useState } from 'react'
import Navbar from '../components/Navbar'
import Slider from '../components/Slider'
import appleIcon from '../assets/images/apple-transparent-icon.png'
import playstoreIcon from '../assets/images/android-transparent-icon.png'
import spin from '../assets/images/spin_button.png'
import androidVideo from '../assets/videos/android.mp4'
import iosVideo from '../assets/videos/ios.mp4'

const Home = () => {

  const [activeTab, setActiveTab] = useState('ios');
  const [videoSource, setVideoSource] = useState(androidVideo)


  const handler = (tab) => {
    setActiveTab(tab)
    setVideoSource('ios' === tab ? iosVideo : androidVideo)
  }

  return (
    <div className='overflow-hidden' >
      <Navbar />
      <section className='flex flex-col items-center h-[43.26vw] ' id='section-1'></section>

      <section className='flex flex-col items-center justify-center lg:p-24 p-8' id='section-2'>
        <div className='lg:mb-8 mb-4' >
          <h1 className='lg:text-5xl text-3xl text-center lg:mb-8'>DUMAN<span className='text-[#92E404]' >BET</span>’<span className='text-regular' ><span className='font-normal' >TE HEYECANI VE BAHİS KEYFİNİ </span></span></h1>
          <h1 className='lg:text-5xl text-3xl text-center lg:mb-8'>GÜVENLE YAŞA!</h1>
        </div>
        <div className='grid lg:grid-cols-4' >
          <div className='flex items-center justify-center flex-col lg:col-span-3 lg:px-16 box-border' >
            <h2 className='lg:text-3xl text-xl text-center lg:mb-10 mb-5' >DUMAN<span className='text-[#92E404]' >BET</span>’LE HER ZAMAN KAZANIN!</h2>
            <p className='text-center' >Dumanbet’te en yüksek bahis oranları, sayısız spor müsabakası, canlı bahis ve birbirinden çeşitli güncel oyunlar ile <span className='font-bold'>her zaman kazanmaya</span> ve <span className='font-bold' >maksimum eğlenceye</span> davetlisiniz!</p>
            <div className='m-auto mt-10 flex gap-5 p-1 mb-10 justify-center' >
              <a  href='#appstore-slider' class="flex mt-3 w-48 h-14 bg-black text-white rounded-xl items-center justify-center border-white border-[1px]">
                <div class="lg:mr-3 mr-1">
                  <svg viewBox="0 0 384 512" width="15" >
                    <path fill="currentColor" d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z" />
                  </svg>
                </div>
                <div>
                  <div class="lg:text-xs text-[8px]">Download on the</div>
                  <div class="lg:text-2xl text-lg font-semibold font-sans -mt-1">App Store</div>
                </div>
              </a>

              <a href='#google-slider' class="flex mt-3 w-48 h-14 bg-black text-white rounded-lg items-center justify-center border-white border-[1px]">
                <div class="lg:mr-3 mr-1">
                  <svg viewBox="30 336.7 120.9 129.2" width="15">
                    <path fill="#FFD400" d="M119.2,421.2c15.3-8.4,27-14.8,28-15.3c3.2-1.7,6.5-6.2,0-9.7  c-2.1-1.1-13.4-7.3-28-15.3l-20.1,20.2L119.2,421.2z" />
                    <path fill="#FF3333" d="M99.1,401.1l-64.2,64.7c1.5,0.2,3.2-0.2,5.2-1.3  c4.2-2.3,48.8-26.7,79.1-43.3L99.1,401.1L99.1,401.1z" />
                    <path fill="#48FF48" d="M99.1,401.1l20.1-20.2c0,0-74.6-40.7-79.1-43.1  c-1.7-1-3.6-1.3-5.3-1L99.1,401.1z" />
                    <path fill="#3BCCFF" d="M99.1,401.1l-64.3-64.3c-2.6,0.6-4.8,2.9-4.8,7.6  c0,7.5,0,107.5,0,113.8c0,4.3,1.7,7.4,4.9,7.7L99.1,401.1z" />
                  </svg>
                </div>
                <div>
                  <div class="lg:text-xs text-[8px]">GET IT ON</div>
                  <div class="lg:text-2xl text-lg font-semibold font-sans -mt-1">Google Play</div>
                </div>
              </a>

            </div>
          </div>
          <div className='flex lg:grid-span-1' >
            <img className='w-full h-auto' src={require('../assets/images/section-2-img-1.png')} />
          </div>
        </div>
      </section>

      <section className='lg:mb-20 mb-5'>
        <div className='p-4' >
          <div className='grid lg:grid-cols-4 grid-cols-2 bg-[#0A2005] gap-3 lg:p-16 p-5 rounded-3xl '>

            <div className='lg:max-w-[213px] max-w-[150px] m-auto' >
              <div className='flex items-center justify-center mb-5 h-[136px]' >
                <img src={require('../assets/images/spin_button.png')} />
              </div>
              <h3 className='text-white font-bold text-center mb-5 text-2xl' >Slot&Oyunlar</h3>
              <p className='text-white text-center'>Ödüllü slot savaşları ve yarışmalar ile oyun ve slotlarda maksimum kazanç elde edin.</p>
            </div>

            <div className='lg:max-w-[213px] max-w-[150px] m-auto' >
              <div className='flex items-center justify-center mb-5 h-[136px]' >
                <img src={require('../assets/images/trophy_button.png')} />
              </div>
              <h3 className='text-white font-bold text-center mb-5 text-2xl' >Ödül Havuzları</h3>
              <p className='text-white text-center'>Yarışmalara katılın ve Milyonlara varan ödül havuzlarıyla %100’den fazla kazanın!</p>
            </div>

            <div className='lg:max-w-[213px] max-w-[150px] m-auto' >
              <div className='flex items-center justify-center mb-5 h-[136px]' >
                <img src={require('../assets/images/ranking_button.png')} />
              </div>
              <h3 className='text-white font-bold text-center mb-5 text-2xl' >Spor Bahisleri</h3>
              <p className='text-white text-center'>Yüzlerce spor müsabakasına en yüksek oranlarla bahis yapın.</p>
            </div>

            <div className='lg:max-w-[213px] max-w-[150px] m-auto' >
              <div className='flex items-center justify-center mb-5 h-[136px]' >
                <img src={require('../assets/images/Varlık10.png')} />
              </div>
              <h3 className='text-white font-bold text-center mb-5 text-2xl' >Maksimum Güvenlik</h3>
              <p className='text-white text-center'>Her an, her yerde DUMANBET ile gönül rahatlığıyla bahsin keyfini çıkarın!</p>
            </div>
          </div>
        </div>

      </section>

      <section className='lg:mb-24 mb-10 relative overflow-hidden relative bg-[#FFFFF]' id='google-slider'>
        <div className='lg:mb-10 mb-5 px-5' >
          <h3 className='lg:text-5xl text-3xl text-center lg:mb-8'>DUMAN<span className='text-[#92E404]' >BET</span>’<span className='text-regular' >i ANDROİD CİHAZINIZA YÜKLEYİN</span></h3>
          <p className='text-center text-lg text-semibold' ><span className='font-bold' >DUMAN<span className='text-[#92E404]' >BET</span></span>’i Android Cihazınıza Yüklemek İçin Aşağıdaki Adımları Takip Edin!</p>
        </div>
        <div className='lg:px-36 px-5 ' >
          <Slider platform='android' />
        </div>
      </section>

      <section className='lg:mb-24 mb-10 overflow-hidden relative' id='appstore-slider'>
        <div className='lg:mb-10 mb-5 px-5' >
          <h3 className='lg:text-5xl text-3xl text-center lg:mb-8'>DUMAN<span className='text-[#92E404]' >BET</span>’<span className='text-regular' >i IOS CİHAZINIZA YÜKLEYİN</span></h3>
          <p className='text-center text-lg' ><span className='font-bold' >DUMAN<span className='text-[#92E404]' >BET</span></span>’i IOS Cihazınıza Yüklemek İçin Aşağıdaki Adımları Takip Edin!</p>
        </div>
        <div className='lg:px-36 px-5' >
          <Slider platform='ios' />
        </div>
      </section>

      <section className='flex flex-col' >

        <div className='mb-10 px-5' >
          <div className='bg-black py-24 rounded-xl' >
            <h3 className='lg:text-5xl text-3xl text-center lg:mb-8'>Cihazınızı Seçin ve DUMAN<span className='text-[#92E404]' >BET</span>’i <span className='text-regular' ></span>Yükleyin</h3>

            <div className='m-auto mt-10 flex gap-5 p-1 mb-10 justify-center' style={{ borderColor: 'black' }}>

              <a onClick={() => { handler('ios') }} class="flex mt-3 w-48 h-14 bg-black text-white rounded-xl items-center justify-center border-white border-[1px]">
                <div class="lg:mr-3 mr-1">
                  <svg viewBox="0 0 384 512" width="15" >
                    <path fill="currentColor" d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z" />
                  </svg>
                </div>
                <div>
                  <div class="lg:text-xs text-[8px]">Download on the</div>
                  <div class="lg:text-2xl text-lg font-semibold font-sans -mt-1">App Store</div>
                </div>
              </a>

              <a onClick={() => { handler('android') }} class="flex mt-3 w-48 h-14 bg-black text-white rounded-lg items-center justify-center border-white border-[1px]">
                <div class="lg:mr-3 mr-1">
                  <svg viewBox="30 336.7 120.9 129.2" width="15">
                    <path fill="#FFD400" d="M119.2,421.2c15.3-8.4,27-14.8,28-15.3c3.2-1.7,6.5-6.2,0-9.7  c-2.1-1.1-13.4-7.3-28-15.3l-20.1,20.2L119.2,421.2z" />
                    <path fill="#FF3333" d="M99.1,401.1l-64.2,64.7c1.5,0.2,3.2-0.2,5.2-1.3  c4.2-2.3,48.8-26.7,79.1-43.3L99.1,401.1L99.1,401.1z" />
                    <path fill="#48FF48" d="M99.1,401.1l20.1-20.2c0,0-74.6-40.7-79.1-43.1  c-1.7-1-3.6-1.3-5.3-1L99.1,401.1z" />
                    <path fill="#3BCCFF" d="M99.1,401.1l-64.3-64.3c-2.6,0.6-4.8,2.9-4.8,7.6  c0,7.5,0,107.5,0,113.8c0,4.3,1.7,7.4,4.9,7.7L99.1,401.1z" />
                  </svg>
                </div>
                <div>
                  <div class="lg:text-xs text-[8px]">GET IT ON</div>
                  <div class="lg:text-2xl text-lg font-semibold font-sans -mt-1">Google Play</div>
                </div>
              </a>
            </div>

            <Player source={videoSource} key={activeTab} />
          </div>
        </div>
      </section>

      <footer className='bg-[#DEFFDD] text-black' >
        <div className='flex lg:flex-row flex-col-reverse justify-center px-6 py-10'>
          <div className='lg:basis-2/3 mt-5 lg:px-20'>
            <div>
              <h2 className='lg:text-5xl text-4xl mt-10 font-normal mb-3'><span className='font-extrabold text-[#343434]' >DUMAN<span className='text-[#92E404]' >BET</span></span>’TE SENİ NELER BEKLİYOR?</h2>
              <p className='text-xl mb-10' >
                DumanBet’te seni bekleyen birçok ayrıcalık var!
              </p>
              <p>DumanBet spor müsabakaları, casino oyunları, slotlar ve daha birçok popüler oyunu bir arada sunuyor.
                Bahis severler, istedikleri her türlü bahis oyununu tek bir platformda yani DUMANBET’te bulabiliyorlar.
                Ayrıca, platformda bulunan oyunları sizler için düzenli olarak güncelliyor ve her geçen gün yenilerini ekliyoruz.
                <br /><br /><br />
                Bununla birlikte, DumanBet kullanıcılarına birçok bonus ve ödül de sunuyor. Yeni kullanıcılara özel hoş geldin bonusları, ilk yatırım bonusları ve daha birçok farklı bonus seçeneği mevcut. Ayrıca, düzenli olarak düzenlenen kampanyalar ve turnuvalarla da kullanıcılara ekstra kazanç imkanı sunuluyor.
              </p>
            </div>
            <div className='max-w-100  mt-5' >
              <div className='flex flex-row gap-5 mb-5'>
                <a className='bg-[#343434] rounded-xl' >
                  <p className='p-3 text-white'>Uygulamayı İndir</p>
                </a>
              </div>
              <div className=''>
                <p className='' >© 2023 Dumanbet</p>
              </div>
            </div>
          </div>
          <div className='lg:basis-1/3' >
            <div>
              <img src={require('../assets/images/section-6-img-1.png')} alt='sekabet banner video' className='mx-auto' />
            </div>
          </div>
        </div>
      </footer>

    </div>
  )
}

export default Home

const Player = ({ key, source }) => {
  return (
    <video key={key} class="lg:w-3/4 border m-auto border-gray-200 rounded-lg dark:border-gray-700" controls>
      <source src={source} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  )
}
