import React from 'react'

const Navbar = () => {
    return (

        <nav class="bg-black lg:px-[32px] h-[54px] lg:h-[84px]">
            <div class="flex items-center justify-between  mx-auto lg:py-5 p-2 h-full w-full">
                <a href="/" class="flex items-center h-full">
                    <img src={require('../assets/images/logo-var1.png')} className="w-full h-full" alt="Flowbite Logo" />
                </a>

                <div className='flex flex-row gap-4  h-full' >
                    <a href='#appstore-slider' className='flex-row flex rounded-full bg-orange bg-[#92E404] p-3 md:m-0 h-full max-w-[40px] lg:max-w-full'>
                        <img src={require('../assets/images/apple.png')} className='lg:mr-2' alt='' />
                        <p className=' text-black self-center font-bold font-sans sm:block hidden' >IOS İçin İndir</p>
                    </a>
                    <a href='#google-slider' className='flex-row flex rounded-full bg-orange bg-[#92E404] p-3 lg:m-0 max-w-[40px] lg:max-w-full'>
                        <img src={require('../assets/images/android.png')} className='lg:mr-2' alt='' />
                        <p className=' text-black self-center font-bold font-sans hidden sm:block ' >Android İçin İndir</p>
                    </a>
                </div>
            </div>
        </nav>

    )
}

export default Navbar